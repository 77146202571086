a, article, aside, audio, b, blockquote, body, canvas, caption, center, dd, details, div, dl, dt, em, embed, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, html, i, iframe, img, label, legend, li, mark, menu, nav, object, ol, p, pre, section, small, span, strike, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, tt, u, ul, video {
  vertical-align: baseline;
  box-sizing: border-box;
  border: 0;
  margin: 0;
  padding: 0;
  font-size: 100%;
  font-weight: 400;
  text-decoration: none;
  list-style: none;
}

* {
  box-sizing: border-box;
  word-wrap: break-word;
  -webkit-tap-highlight-color: transparent;
  margin: 0;
  padding: 0;
}

:after, :before {
  box-sizing: border-box;
}

a {
  color: inherit;
  outline: 0;
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
  display: block;
}

:focus {
  box-shadow: none;
  outline: 0;
}

a:hover {
  cursor: pointer;
}

button, input, textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  box-shadow: none;
  font-family: inherit;
  line-height: normal;
  font-weight: inherit;
  border: none;
  border-radius: 0;
  outline: 0;
}

button {
  cursor: pointer;
  border: none;
  display: inline-block;
}

textarea {
  vertical-align: top;
  resize: vertical;
  overflow: auto;
}

input[type="submit"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
}

a:focus, a:hover, button:focus, button:hover, textarea:focus {
  cursor: pointer;
  outline: 0;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

ul {
  list-style: none;
}

html {
  scroll-behavior: smooth;
  -webkit-text-size-adjust: 100%;
  height: 100%;
  scroll-padding-top: 195px;
  font-size: 62.5%;
}

@font-face {
  font-family: poppins-bold;
  font-weight: 700;
  src: url("Poppins-Bold.dacecb1a.woff") format("woff");
}

@font-face {
  font-family: poppins-medium;
  font-weight: 500;
  src: url("Poppins-Medium.175bb3b0.woff") format("woff");
}

@font-face {
  font-family: poppins;
  font-weight: 400;
  src: url("Poppins-Regular.6c5d0fe0.woff") format("woff");
}

body {
  color: #000;
  -webkit-font-smoothing: antialiased;
  background: #fff;
  flex-direction: column;
  height: 100%;
  font-family: poppins, Arial, Helvetica, sans-serif;
  font-weight: 400;
  transition: all .3s;
  display: flex;
  overflow-x: hidden;
}

.container {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 16px;
  position: relative;
  overflow: hidden;
}

.main-wrap {
  flex: 1 0 auto;
}
/*# sourceMappingURL=index.b3f15460.css.map */
