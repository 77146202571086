a,
article,
aside,
audio,
b,
blockquote,
body,
canvas,
caption,
center,
dd,
details,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
p,
pre,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-weight: 400;
  vertical-align: baseline;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  word-wrap: break-word;
  -webkit-tap-highlight-color: transparent;
}
:after,
:before {
  box-sizing: border-box;
}
a {
  color: inherit;
  text-decoration: none;
  outline: 0;
}
img {
  width: 100%;
  height: auto;
  display: block;
}
:focus {
  outline: 0;
  box-shadow: none;
}
a:hover {
  cursor: pointer;
}
button,
input,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  border-radius: 0;
  line-height: normal;
  outline: 0;
  box-shadow: none;
  border: none;
  font-family: inherit;
  font-weight: inherit;
}
button {
  display: inline-block;
  cursor: pointer;
  border: none;
}
textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}
input[type="submit"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
}
a:focus,
a:hover,
button:focus,
button:hover,
textarea:focus {
  outline: 0;
  cursor: pointer;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
ul {
  list-style: none;
}
html {
  font-size: 62.5%;
  scroll-behavior: smooth;
  height: 100%;
  -webkit-text-size-adjust: 100%;
}
html {
  scroll-padding-top: 195px;
}
@font-face {
  font-family: "poppins-bold";
  font-weight: 700;
  src: url("./../fonts/Poppins-Bold.woff") format("woff");
}
@font-face {
  font-family: "poppins-medium";
  font-weight: 500;
  src: url("./../fonts/Poppins-Medium.woff") format("woff");
}
@font-face {
  font-family: "poppins";
  font-weight: 400;
  src: url("./../fonts/Poppins-Regular.woff") format("woff");
}

body {
  font-family: "poppins", Arial, Helvetica, sans-serif;
  font-weight: 400;
  color: #000;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  height: 100%;
  background: #ffffff;
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
}

.container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 16px;
  width: 100%;
  position: relative;
  overflow: hidden;
}
.main-wrap {
  flex: 1 0 auto;
}
